import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this.storage = storage;
  }
  // Create and expose methods that users of this service can
  // call, for example:
  set = async (key: string, value: any) => {
    await this.storage.set(key, value);
  };
  get = async (key: string) => {
    const s = await this.storage.get(key);
    return s;
  };
  remove = async (key: string) => {
    await this.storage.remove(key);
  };

  clear = async () => {
    await this.storage.clear();
  };
}
